import { createContext, useState } from "react";
import './NotifyContext.css'

const NotifyContext = createContext({
    displayNotification:()=>{}
})

const NotifyProvider = ({children})=>{
    const [notification, setNotification] = useState('')
    const [showNotification, setShowNotification] = useState(false)

    const displayNotification = (message)=>{
        setNotification(message)
        setShowNotification(true)
        setTimeout(() => {
            setShowNotification(false);
        }, 4000);
    }

    return <NotifyContext.Provider value={{displayNotification}}>
        {children}
        <div className={`notification ${showNotification ? 'active' : ''}`}>
            <p>{notification}</p>
        </div>
    </NotifyContext.Provider>
}

export {NotifyContext, NotifyProvider}