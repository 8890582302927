import { useContext, useEffect, useState } from 'react'
import ColorizedImage from '../ColorizedImage'
import { FirebaseContext } from '../../Contexts/Firebase/FirebaseContext'
import '../ReturnToTop/ReturnToTop.css'

export default function WhatsAppLink(){
    const {data} = useContext(FirebaseContext)
    const [isTop, setIsTop] = useState(window.scrollY===0)

    useEffect(()=>{
        const checkScroll = ()=>{
            setIsTop(window.scrollY===0)
        }

        window.addEventListener('scroll', checkScroll)

        return ()=>{
            window.removeEventListener('scroll', checkScroll)
        }
    }, [])

    return data['NavBar/Media'] && <div style={{bottom:`${isTop?'1rem':'5rem'}`}} className='return-to-top' onClick={()=>window.open(data['NavBar/Media'].whatsApp.link, '_blank')}>
        <div className='inner-return'>
            <ColorizedImage 
                width='1.5rem'
                height='1.5rem'
                imageWidth='1.5rem'
                imageHeight='1.5rem'
                url={data['NavBar/Media'].whatsApp.src}
                color='#E1F7F5'
            />
        </div>
    </div>
}