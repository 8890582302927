import { lazy, Suspense, useContext, useEffect } from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import NavBar from "./Navbar/Navbar"
import Footer from './Footer/Footer'
import ReturnToTop from './Components/ReturnToTop/ReturnToTop'
import { FirebaseContext } from './Contexts/Firebase/FirebaseContext'
import LoadingPage from './DisplayPages/LoadingPage/LoadingPage'
import WhatsAppLink from './Components/WhatsAppLink/WhatsAppLink'

const Home = lazy(()=>import('./Pages/Home/Home'))
const About = lazy(()=>import('./Pages/About/About'))
const Cruises = lazy(()=>import('./Pages/Cruises/Cruises'))
const Tours = lazy(()=>import('./Pages/Tours/Tours'))
const Intercity = lazy(()=>import('./Pages/Intercity'))
const SubCruises = lazy(()=>import('./Pages/Cruises/SubCruises'))
const SubTours = lazy(()=>import('./Pages/Tours/SubTours'))
const NotFound = lazy(()=>import('./Pages/NotFound'))
const IntercityPage = lazy(()=>import('./DisplayPages/IntercityPage/IntercityPage'))
const CruiseTourPage = lazy(()=>import('./DisplayPages/CruiseTourPage/CruiseTourPage'))
const GuidePage = lazy(()=>import('./DisplayPages/GuidePage/GuidePage'))
const Reviews = lazy(()=>import('./Footer/FooterPages/Review/Reviews'))
const PressPage = lazy(()=>import('./Footer/FooterPages/Press/PressPage'))
const Press = lazy(()=>import('./Footer/FooterPages/Press/Press'))
const FooterPage = lazy(()=>import('./Footer/FooterPages/FooterPage'))

export default function App(){
  const location = useLocation()
  const {data} = useContext(FirebaseContext)

  useEffect(()=>{
    if(window.location.hostname.includes('herokuapp.com') || !window.location.protocol.includes('https')) window.location.href = 'https://www.halongbaytravelasia.com/'
  },[])

  useEffect(()=>{
    window.scrollTo({ top: 0 })
  },[location])

  return <>
    <section aria-label='Page View' className='page-shell'>
      <Suspense fallback={<LoadingPage/>}>
        <Routes>
          <Route element={<NavBar />}>
            <Route path='/Reviews' element={<Reviews/>}/>
            <Route path='/Press' element={<Press/>}/>
            <Route path='/Press/:v' element={<PressPage/>}/>
            <Route path='/Guide/:v' element={<GuidePage/>}/>
            <Route path="/AboutUs" element={<About/>}/>
            <Route path='/Cruises'>
              <Route index element={<Cruises/>}/>
              {data['Pages/Cruise'] && Object.keys(data['Pages/Cruise']).map((value, index)=>{
                const title = value.replace(/_/g, " ")
                const cruiseData = data['Pages/Cruise'][value].map(({mainImageSrc, destinationText, cruiseName, cabinCount, cost, link})=>{
                  return {
                    mainImageSrc,
                    destinationText,
                    cruiseName,
                    cabinCount,
                    cost,
                    link
                  }
                })

                return <Route key={"App Cruise "+index}
                  path={'/Cruises/'+value} 
                  element={<SubCruises key={title+index} cruiseData={cruiseData} title={title} />} 
                />
              })}
            </Route>
            <Route path='/Tours'>
              <Route index element={<Tours/>}/>
              {data['Pages/Tours'] && Object.keys(data['Pages/Tours']).map((value, index)=>{
                const title = value.replace(/_/g, " ")
                const tourData = data['Pages/Tours'][value].map(({mainImageSrc, destinationText, tourName, time, hours, cost, link})=>{
                  return{
                    mainImageSrc,
                    destinationText,
                    tourName,
                    time,
                    cost,
                    hours,
                    link
                  }
                })

                return <Route key={"App Tour "+index}
                  path={'/Tours/'+value} 
                  element={<SubTours key={title+index} tourData={tourData} title={title} />} 
                />
              })}
            </Route>
            <Route path='/Intercity' >
              <Route index element={<Intercity/>}/>
              <Route path='/Intercity/View/:v' element={<IntercityPage />} />
            </Route>
            <Route path='/:p/:v' element={<CruiseTourPage/>}/>
            <Route path='/:v' element={<FooterPage />} />
            <Route path="/" element={<Home/>}/>
            <Route path='*' element={<NotFound/>}/>
          </Route>
        </Routes>
        <Footer/>
      </Suspense>
    </section>
    <WhatsAppLink />
    <ReturnToTop />
  </>
}