import { NavLink } from 'react-router-dom'
import './Footer.css'
import { useContext } from 'react'
import { FirebaseContext } from '../Contexts/Firebase/FirebaseContext'
import CopyOnCLick from '../Components/CopyOnCLick'
import ColorizedImage from '../Components/ColorizedImage'

export default function Footer(){
    const {data} = useContext(FirebaseContext)

    return data['Pages/AboutUs'] && <section aria-label='Footer' id='footer'>
        <section aria-label='Page Links' id='page-links'>
            <section id='companyMessage' aria-label='Company Logo And Mission Statement'>
                <ColorizedImage url={data['Pages/AboutUs'].logo} color='gold' height='7rem' width='7rem' imageHeight='7rem' imageWidth='7rem' />
                <p>{data['Pages/AboutUs'].footer_message}</p>
            </section>
            <section aria-label='General'>
                <h3>General</h3>
                <ul>
                    <li><NavLink to={'/AboutUs'}>&gt; About Us</NavLink></li>
                    <li><NavLink to={'/Reviews'}>&gt; Reviews</NavLink></li>
                    <li><NavLink to={'/Press'}>&gt; Press</NavLink></li>
                </ul>
            </section>
            <section aria-label='Information'>
                <h3>Useful Info</h3>
                <ul>
                    <li><NavLink to={'/PaymentInstructions'}>&gt; Payment Instructions</NavLink></li>
                    <li><NavLink to={'/RefundPolicy'}>&gt; Refund Policy</NavLink></li>
                    <li><NavLink to={'/TermsAndConditions'}>&gt; Terms & Conditions</NavLink></li>
                    <li><NavLink to={'/Privacy'}>&gt; Privacy</NavLink></li>
                    <li><NavLink to={'/ImageCopyrights'}>&gt; Image Copyrights</NavLink></li>
                </ul>
            </section>
            <section aria-label='Contact'>
                <h3>Contact</h3>
                <h4>Ha Long Bay Travel Company</h4>
                <ul>
                    <li onClick={()=>window.open(`${data['Pages/AboutUs'].office.maps}`, '_blank')}>                     
                        <ColorizedImage 
                            width='1rem' 
                            height='1rem' 
                            imageWidth='1rem' 
                            imageHeight='1rem' 
                            url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718787088/Icons/marker-52_b3dop8.png'
                            color='#E1F7F5' 
                        />
                        <p>{data['Pages/AboutUs'].office.address}</p>
                    </li>
                    <li  onClick={()=>window.location.href = `tel:${data['Pages/AboutUs'].office.phone_number?.replace(/\s/g, "")}`}>                  
                        <ColorizedImage 
                            width='1rem' 
                            height='1rem' 
                            imageWidth='1rem' 
                            imageHeight='1rem' 
                            url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718259962/Icons/phone-100_jb5piq.png'
                            color='#E1F7F5' 
                        />
                        <p>{data['Pages/AboutUs'].office.phone_number}</p>
                    </li>
                    <li  onClick={()=>window.location = `mailto:${data['Pages/AboutUs'].email}`}>                  
                        <ColorizedImage 
                            width='1rem' 
                            height='1rem' 
                            imageWidth='1rem' 
                            imageHeight='1rem' 
                            url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718353845/Icons/letter-100_r6539t.png'
                            color='#E1F7F5' 
                        />
                        <p>{data['Pages/AboutUs'].email}</p>
                    </li>
                </ul>
            </section>
        </section>
            <div aria-label='Buisness License' className='business-text'>Company Registration Number: <CopyOnCLick text={data['Pages/AboutUs'].company_registration_number}><b>{data['Pages/AboutUs'].company_registration_number}</b></CopyOnCLick>, issued by {data['Pages/AboutUs'].company_registration_company}</div>
        <div id='separator-bar'/>
        <p aria-label='Copyright' className='business-text'>Copyright © 2023-{(new Date().getFullYear())} Ha Long Bay Travel</p>
    </section>
}