import { NavLink } from 'react-router-dom'
import './Dropdown.css'

export default function Dropdown({children, dropdownElements}){    
  return <div className="App">
    <p className="hover-target">{children}</p>
    <div className="hover-content">
      <ul>
          {dropdownElements.map((element, index)=>{
              return <li key={index}> 
                <NavLink to={element.to}>
                    {element.text}
                </NavLink>
              </li>
          })}
      </ul>
    </div>
  </div>
}