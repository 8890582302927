import { createContext, useEffect, useState } from "react";
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged } from "firebase/auth";

const FirebaseAuthContext = createContext({
    user:{},
    userError:{},
    signInWithGoogle:()=>{},
    signOutFromAccount:()=>{},
})

const FirebaseAuthProvider = ({children})=>{
    const [app, setApp] = useState()
    const [auth, setAuth] = useState()
    const [user, setUser] = useState()
    const [userError, setUserError] = useState()

    //Initialize FIrebase
    useEffect(()=>{
        if(!app){
            setApp(initializeApp(JSON.parse(process.env.REACT_APP_FIRESTORE_CREDS)))
        }
    }, [app])

    //Initialize Authentication
    useEffect(()=>{
        if(app && !auth){
            const authentication = getAuth(app)
            authentication.useDeviceLanguage()
            setAuth(authentication)

            onAuthStateChanged(authentication, (user)=>{
                setUser(user)
                setUserError(null)
            }, (error)=>{
                setUserError(error.message)
            })
        }
    }, [app, auth])

    /**Sign out from the current account */
    const signOutFromAccount = ()=>{
        // signOut(auth)
        auth.signOut().then(()=>{
            console.log('signed out')
        })
    }

    /**Alows the user to sign in with a chosen google account */
    const signInWithGoogle = ()=>{
        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        signInWithPopup(auth, provider)
    }

    return <FirebaseAuthContext.Provider value={{signInWithGoogle, signOutFromAccount, user, userError}}>
        {children}
    </FirebaseAuthContext.Provider>
}

export {FirebaseAuthContext, FirebaseAuthProvider}