import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { FirebaseAuthContext } from './FirebaseAuth';
import { NotifyContext } from '../Notification/NotifyContext';
import axios from 'axios'

const FirebaseContext = createContext({
  data : {},
  isLoading : {},
  error : {},
  fetchData:()=>{},
  requestEmail:()=>{},
})

const ReviewContext = createContext({
  hasMoreReviews:{},
  sendReview:()=>{},
  sendVote:()=>{},
  getMostRelevantReviews:()=>{},
  GetNewestReviews:()=>{},
  GetOldestReviews:()=>{},
  GetStarsAtValueReviews:()=>{},
  GetStarsLTH:()=>{},
  GetStarsHTL:()=>{},
})

const FirebaseProvider = ({ children }) => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState({})
  const [error, setError] = useState({})
  const {user} = useContext(FirebaseAuthContext)
  const {displayNotification} = useContext(NotifyContext)
  const [reviewIndex, setReviewIndex] = useState()
  const [hasMoreReviews, setHasMoreReviews] = useState(true)

  //#region Firebase Fetch
  const fetchData = useCallback(async (endpoint)=>{
    if(!data[endpoint] && !isLoading[endpoint]){
      setIsLoading(prev =>({...prev, [endpoint] : true}));
      setError(prev => ({...prev, [endpoint]:null}));
  
      try{
        const res = await axios.get(`/api/${endpoint}`)

        if(res.status===200){
          const data = await res.data

          setData(prev=>({...prev, [endpoint]:data}))
        }
        else{
          console.log(`error getting data from ${endpoint}`)
        }
      }
      catch(e){
        console.error("Error fetching data:", e)
        setError(prev=>({...prev, [endpoint]:e.message}))
      }
      finally{
        setIsLoading(prev=>({...prev, [endpoint]:false}))
      }
    }
  }, [data, isLoading, setError, setData])

  const requestEmail = async(email, data)=>{
    //verify email
    const atIndex = email.indexOf('@')
    if(atIndex===-1 || email.indexOf('.', atIndex+1) === -1) {
      displayNotification('Please, enter an email.')
      return
    }

    try{
      const res = await axios.post(`/api/sendEmail/${email}`, data)

      if(res.status===200){
        displayNotification('Email sent. We will contact you shortly.')
      }
      else{
        displayNotification('There was an error sending an email. Try again later.')
      }
    }
    catch(e){
      console.log(e.message)
      displayNotification('There was an error sending an email. Try again later.')
    }
  }
  //#endregion

  //#region Review
  /**
   * If the user is signed into FirebaseAuth and they have selected a star amount 
   * send the review to the database
   * @param {*} reviewObject Contains the review message and star amount
   */
  const sendReview = async(reviewObject)=>{
    //check if signed in
    if(user){
      const reviewData = {
        uid:user.uid,
        createdAt:new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        displayName:user.displayName,
        photoURL:user.photoURL,
        message:reviewObject.message,
        stars:reviewObject.stars,
        voteValue:0,
        votes:[],
      }

      try{
        const res = await axios.post('/api/addReview', reviewData)

        if(res.status===200) displayNotification('Sent review')
        else displayNotification('Error sending review. Try again later.')
      }
      catch(e){
        displayNotification('Error sending review. Try again later.')
        console.log(`error sending review ${e.message}`)
      }
    }
    else{
      displayNotification('Please, sign in to leave a review.')
    }
  }

  const sendVote = async(review)=>{
    if(user){
        try{
            const res = await axios.post('/api/sendVote', review)
    
            if(res.status===200) displayNotification('Sent vote')
            else displayNotification('Error voting on the review. Try again later.')
        }
        catch(e){
            displayNotification('Error voting on the review. Try again later.')
            console.log(`error voting on the review ${e.message}`)
        }
    }
    else{
        displayNotification('Please, sign in to vote on a review.')
    }
  }

  const getMostRelevantReviews = async()=>{
    if(reviewIndex==='relevant' && data.reviews){
      console.log('update reviews')
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetMostRelevantReviews', {voteValue:lastElement.voteValue, createdAt:lastElement.createdAt, limit:10})
    
            if(res.status===200){
                const resData = await res.data
    
                setHasMoreReviews(resData.length === 10)
          
                setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
          console.error("Error fetching data:", e)
          displayNotification('Error geting reviews. Try again later.')
          setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
          setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
      data.reviews = null
      setReviewIndex('relevant')
      //get initial data
      if((!data.reviews && !isLoading.reviews)){
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetMostRelevantReviews', {limit:10})

            if(res.status===200){
                const fetchedData = await res.data

                setHasMoreReviews(fetchedData.length === 10)

                setData(prev => ({...prev, reviews:fetchedData}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
          console.error("Error fetching data:", e)
          displayNotification('Error geting reviews. Try again later.')
          setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
          setIsLoading(prev=>({...prev, reviews:false}))
        }
      }
    }
  }

  const GetNewestReviews = async()=>{
    //createdAt, limit
    if(reviewIndex==='newest' && data.reviews){
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetNewestReviews', {createdAt:lastElement.createdAt, limit:10})

            if(res.status===200){
                const resData = await res.data

                setHasMoreReviews(resData.length === 10)
        
                setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
            console.error("Error fetching data:", e)
            displayNotification('Error geting reviews. Try again later.')
            setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
            setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
        data.reviews = null
        setReviewIndex('newest')
        //get initial data
        if((!data.reviews && !isLoading.reviews) || reviewIndex!=='newest'){
            setIsLoading(prev =>({...prev, reviews : true}));
            setError(prev => ({...prev, reviews:null}));

            try{
                const res = await axios.post('/api/GetNewestReviews', {limit:10})
        
                if(res.status===200){
                    const resData = await res.data
        
                    setHasMoreReviews(resData.length === 10)
              
                    setData(prev => ({...prev, reviews:resData}))
                }
                else displayNotification('Error geting reviews. Try again later.')
            }
            catch(e){
                console.error("Error fetching data:", e)
                displayNotification('Error geting reviews. Try again later.')
                setError(prev=>({...prev, reviews:e.message}))
            }
            finally{
                setIsLoading(prev=>({...prev, reviews:false}))
            }
        }
    }
  }

  const GetOldestReviews = async()=>{
    if(reviewIndex==='oldest' && data.reviews){
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetOldestReviews', {createdAt:lastElement.createdAt, limit:10})

            if(res.status===200){
                const resData = await res.data

                setHasMoreReviews(resData.length === 10)
        
                setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
            console.error("Error fetching data:", e)
            displayNotification('Error geting reviews. Try again later.')
            setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
            setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
        data.reviews = null
        setReviewIndex('oldest')
        //get initial data
        if((!data.reviews && !isLoading.reviews) || reviewIndex!=='oldest'){
            setIsLoading(prevLoading =>({...prevLoading, reviews : true}));
            setError(prevError => ({...prevError, reviews:null}));

            try{
                const res = await axios.post('/api/GetOldestReviews', {limit:10})
    
                if(res.status===200){
                    const resData = await res.data
    
                    setHasMoreReviews(resData.length === 10)
            
                    setData(prev => ({...prev, reviews:resData}))
                }
                else displayNotification('Error geting reviews. Try again later.')
            }
            catch(e){
                console.error("Error fetching data:", e)
                displayNotification('Error geting reviews. Try again later.')
                setError(prevError=>({...prevError, reviews:e.message}))
            }
            finally{
                setIsLoading(prevLoading=>({...prevLoading, reviews:false}))
            }
        }
    }
  }

  const GetStarsAtValueReviews = async(starValue)=>{
    if(reviewIndex===`starValue${starValue}` && data.reviews){
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetStarsAtValueReviews', {stars:starValue, createdAt:lastElement.createdAt, limit:10})

            if(res.status===200){
                const resData = await res.data

                setHasMoreReviews(resData.length === 10)
        
                setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
            console.error("Error fetching data:", e)
            displayNotification('Error geting reviews. Try again later.')
            setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
            setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
        data.reviews = null
        setReviewIndex(`starValue${starValue}`)
        //get initial data
        if((!data.reviews && !isLoading.reviews) || reviewIndex!==`starValue${starValue}`){
            setIsLoading(prev =>({...prev, reviews : true}));
            setError(prev => ({...prev, reviews:null}));

            try{
                const res = await axios.post('/api/GetStarsAtValueReviews', {stars:starValue, limit:10})
    
                if(res.status===200){
                    const resData = await res.data
    
                    setHasMoreReviews(resData.length === 10)
            
                    setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
                }
                else displayNotification('Error geting reviews. Try again later.')
            }
            catch(e){
                console.error("Error fetching data:", e)
                displayNotification('Error geting reviews. Try again later.')
                setError(prev=>({...prev, reviews:e.message}))
            }
            finally{
                setIsLoading(prev=>({...prev, reviews:false}))
            }
        }
    }
  }

  const GetStarsLTH = async()=>{
    if(reviewIndex==='starsLTH' && data.reviews){
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));
        
        try{
          const res = await axios.post('/api/GetStarsLTH', {exactStars:lastElement.stars, createdAt:lastElement.createdAt, limit:10})

          if(res.status===200){
            const resData = await res.data

            setHasMoreReviews(resData.length === 10)
    
            setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
          }
          else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
            console.error("Error fetching data:", e)
            displayNotification('Error geting reviews. Try again later.')
            setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
            setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
        data.reviews = null
        setReviewIndex('starsLTH')
        //get initial data
        if((!data.reviews && !isLoading.reviews) || reviewIndex!=='starsLTH'){
            setIsLoading(prev =>({...prev, reviews : true}));
            setError(prev => ({...prev, reviews:null}));
        
            try{
                const res = await axios.post('/api/GetStarsLTH', {limit:10})
    
                if(res.status===200){
                    const resData = await res.data
    
                    setHasMoreReviews(resData.length === 10)
            
                    setData(prev => ({...prev, reviews:resData}))
                }
                else displayNotification('Error geting reviews. Try again later.')
            }
            catch(e){
                console.error("Error fetching data:", e)
                displayNotification('Error geting reviews. Try again later.')
                setError(prev=>({...prev, reviews:e.message}))
            }
            finally{
                setIsLoading(prev=>({...prev, reviews:false}))
            }
        }
    }
  }

  const GetStarsHTL = async()=>{
    if(reviewIndex==='GetStarsHTL' && data.reviews){
        const [lastElement] = data.reviews.slice(-1)
        setIsLoading(prev =>({...prev, reviews : true}));
        setError(prev => ({...prev, reviews:null}));

        try{
            const res = await axios.post('/api/GetStarsHTL', {exactStars:lastElement.stars, createdAt:lastElement.createdAt, limit:10})

            if(res.status===200){
                const resData = await res.data

                setHasMoreReviews(resData.length === 10)
        
                setData(prev => ({...prev, reviews:[...data.reviews, ...resData]}))
            }
            else displayNotification('Error geting reviews. Try again later.')
        }
        catch(e){
            console.error("Error fetching data:", e)
            displayNotification('Error geting reviews. Try again later.')
            setError(prev=>({...prev, reviews:e.message}))
        }
        finally{
            setIsLoading(prev=>({...prev, reviews:false}))
        }
    }else{
        data.reviews = null
        setReviewIndex('GetStarsHTL')
        //get initial data
        if((!data.reviews && !isLoading.reviews) || reviewIndex!=='GetStarsHTL'){
            setIsLoading(prevLoading =>({...prevLoading, reviews : true}));
            setError(prevError => ({...prevError, reviews:null}));

            try{
                const res = await axios.post('/api/GetStarsHTL', {limit:10})
    
                if(res.status===200){
                    const resData = await res.data
    
                    setHasMoreReviews(resData.length === 10)
            
                    setData(prev => ({...prev, reviews:resData}))
                }
                else displayNotification('Error geting reviews. Try again later.')
            }
            catch(e){
                console.error("Error fetching data:", e)
                displayNotification('Error geting reviews. Try again later.')
                setError(prev=>({...prev, reviews:e.message}))
            }
            finally{
                setIsLoading(prev=>({...prev, reviews:false}))
            }
        }
    }
  }
  //#endregion

  //#region Load Data on start
  useEffect(() => {
    if(!data['Pages/Home']) fetchData('Pages/Home')
    if(!data['Pages/Cruise']) fetchData('Pages/Cruise')
    if(!data['Pages/Tours']) fetchData('Pages/Tours')
    if(!data['Pages/AboutUs']) fetchData('Pages/AboutUs')
    if(!data['NavBar/Media']) fetchData('NavBar/Media')
    if(!data['Pages/Intercity']) fetchData('Pages/Intercity')
  }, [data, fetchData])
  //#endregion

  return (
    <FirebaseContext.Provider value={{ data, isLoading, error, fetchData, requestEmail, }}>
      <ReviewContext.Provider value={{sendReview, sendVote, getMostRelevantReviews, GetNewestReviews, GetOldestReviews, GetStarsAtValueReviews, GetStarsLTH, GetStarsHTL, hasMoreReviews, }}>
        {children}
      </ReviewContext.Provider>
    </FirebaseContext.Provider>
  );
};

export {FirebaseContext, ReviewContext, FirebaseProvider}