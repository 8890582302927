import {NavLink, Outlet, useLocation} from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import Dropdown from './Dropdown/Dropdown'
import { FirebaseContext } from '../Contexts/Firebase/FirebaseContext'
import ColorizedImage from '../Components/ColorizedImage'
import './Navbar.css'

export default function NavBar(){
    const [isTop, setIsTop] = useState(window.scrollY===0)
    const {data} = useContext(FirebaseContext)
    const [displayHamburger, setDisplayHamburger] = useState(window.innerWidth < 786)
    const scriptRef = useRef()

    //set scroll
    useEffect(()=>{
        const checkScroll = ()=>{
            setIsTop(window.scrollY===0)
        }

        window.addEventListener('scroll', checkScroll)

        return ()=>{
            window.removeEventListener('scroll', checkScroll)
        }
    }, [])

    //toggle screen width
    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < 786 && !displayHamburger){
                setDisplayHamburger(true)
                window.location.reload()
            }
            else if(window.innerWidth > 785 && displayHamburger){
                setDisplayHamburger(false)
                window.location.reload()
            }
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize)
    }, [displayHamburger])

    return (data['Pages/AboutUs'] && data['Pages/Cruise'] && data['Pages/Tours'] && data['NavBar/Media']) ? <>
        {
            displayHamburger ?
                <NavBarHamburger data={data} scriptRef={scriptRef} />
            :
                <NavBarWide data={data}  scriptRef={scriptRef} isTop={isTop}/>
        }
        <Outlet/>
    </> : <></>
}

function NavBarWide({data, scriptRef, isTop}){
    return <nav className="nav">
        {isTop && <div className='info-bar'>
            <ul>
                <li aria-label={`Contact Us at ${data['Pages/AboutUs'].office.phone_number}`} onClick={()=>window.location.href = `tel:${data['Pages/AboutUs'].office.phone_number?.replace(/\s/g, "")}`}>
                    <div>
                        <ColorizedImage width='1rem' height='1rem' imageWidth='1rem' imageHeight='1rem' url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718259962/Icons/phone-100_jb5piq.png' color='#E1F7F5' />
                        <p>{data['Pages/AboutUs'].office.phone_number}</p>
                    </div>
                </li>    
                <li 
                    aria-label='Google Maps Location'
                    onClick={()=>window.open(`${data['Pages/AboutUs'].office.maps}`, '_blank')}
                >
                    <div>
                        <ColorizedImage width='1rem' height='1rem' imageWidth='1rem' imageHeight='1rem' url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718787088/Icons/marker-52_b3dop8.png' color='#E1F7F5' />
                        <p>{data['Pages/AboutUs'].office.address}</p>
                    </div>
                </li>
            </ul>
            <ul className='Media-links'>
                {data['NavBar/Media'] && Object.keys(data['NavBar/Media']).map((key, index)=>{
                    return <li key={index} >                        
                        <ColorizedImage 
                            width='2rem' 
                            height='2rem' 
                            imageWidth='2rem' 
                            imageHeight='2rem' 
                            url={data['NavBar/Media'][key].src}
                            color='#E1F7F5' 
                            onClick={()=>window.open(data['NavBar/Media'][key].link, '_blank')}
                        />
                    </li>
                })}
            </ul>
        </div>}
        <header>
            <NavLink to="/">  
                <div  className="site-title">
                    <ColorizedImage 
                        width='3rem' 
                        height='3rem' 
                        imageWidth='3rem' 
                        imageHeight='3rem' 
                        url={data['Pages/AboutUs'].logo}
                        color='gold' 
                    />
                </div>
            </NavLink>
            <ul>
                <li>
                    <NavLink to='/'>Home</NavLink>
                </li>
                <li>
                    <NavLink to="/AboutUs">About Us</NavLink>
                </li>
                <li>
                    {data['Pages/Cruise'] && <Dropdown 
                        dropdownElements={Object.keys(data['Pages/Cruise']).map(value=>{
                            const title = value.replace(/_/g, " ")
            
                            return {
                                text:title+' Bay Cruises',
                                to:'/Cruises/'+value
                            }
                        })} 
                        >
                        <NavLink to="/Cruises">Cruises</NavLink>
                    </Dropdown>}
                </li>
                <li>
                    {data['Pages/Tours'] && <Dropdown 
                        dropdownElements={Object.keys(data['Pages/Tours']).map(value=>{
                            const title = value.replace(/_/g, " ")
            
                            return {
                                text:title+' Tours',
                                to:'/Tours/'+value
                            }
                        })} 
                        >
                        <NavLink to="/Tours">Tours</NavLink>
                    </Dropdown>}
                </li>
                <li>
                    <NavLink to="/Intercity">Intercity</NavLink>
                </li>
            </ul>
            <TranslateElement scriptRef={scriptRef}/>
        </header>
    </nav>
}

function NavBarHamburger({data, scriptRef}){
    const location = useLocation()

    return <nav className='nav'>
        <header>
            <NavLink to="/">  
                <div  className="site-title">
                    <ColorizedImage 
                        width='3rem' 
                        height='3rem' 
                        imageWidth='3rem' 
                        imageHeight='3rem' 
                        url={data['Pages/AboutUs'].logo}
                        color='gold' 
                    />
                </div>
            </NavLink>
            <div id='hamburgerMenu' >
                <ColorizedImage
                    width='3rem' 
                    height='3rem' 
                    imageWidth='3rem' 
                    imageHeight='3rem' 
                    color='#1E0342'
                    url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718861725/Icons/menu-144_piaea9.png'
                />
            </div>
            <div className='dropdownMenu'>
                <NavLink to='/'>Home</NavLink>
                <NavLink to="/AboutUs">About Us</NavLink>
                <div className='dropDown'>
                    <NavLink className={location.pathname.includes('/Cruises')?'avtive':'innactive'} id='cruiseMenu' >Cruises</NavLink>
                    {data['Pages/Cruise'] && <div id='cruiseDropdown'>
                        <NavLink id='cruiseMenu' to="/Cruises">All Cruises</NavLink>
                        {Object.keys(data['Pages/Cruise']).map((value, index)=>{
                            const title = value.replace(/_/g, " ")
            
                            return <NavLink key={index} to={`/Cruises/${value}`}>{`${title} Bay Cruises`}</NavLink>
                        })} 
                    </div>}
                </div>
                <div className='dropDown'>
                    <NavLink className={location.pathname.includes('/Tours')?'avtive':'innactive'} id='tourMenu'>Tours</NavLink>
                    {data['Pages/Tours'] && <div id='tourDropdown'>
                        <NavLink to="/Tours" id='tourMenu'>All Tours</NavLink>
                        {Object.keys(data['Pages/Tours']).map((value, index)=>{
                            const title = value.replace(/_/g, " ")
            
                            return <NavLink key={index} to={`/Tours/${value}`}>{`${title} Tours`}</NavLink>
                        })} 
                    </div>}
                </div>
                <NavLink to="/Intercity">Intercity</NavLink>
                <TranslateElement scriptRef={scriptRef}/>  
                <div 
                    style={{display:'flex'}}
                    className='contact' 
                    aria-label={`Contact Us at ${data['Pages/AboutUs'].office.phone_number}`} 
                    onClick={()=>window.location.href = `tel:${data['Pages/AboutUs'].office.phone_number?.replace(/\s/g, "")}`}
                >
                    <ColorizedImage width='1rem' height='1rem' imageWidth='1rem' imageHeight='1rem' url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718259962/Icons/phone-100_jb5piq.png' color='#1E0342' />
                    <p>{data['Pages/AboutUs'].office.phone_number}</p>
                </div>    
                <div
                    style={{display:'flex'}}
                    className='contact' 
                    aria-label='Google Maps Location'
                    onClick={()=>window.open(`${data['Pages/AboutUs'].office.maps}`, '_blank')}
                >
                    <ColorizedImage width='1rem' height='1rem' imageWidth='1rem' imageHeight='1rem' url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718787088/Icons/marker-52_b3dop8.png' color='#1E0342' />
                    <p>{data['Pages/AboutUs'].office.address}</p>
                </div>
                <div id='media' style={{display:'flex'}}>
                    {data['NavBar/Media'] && Object.keys(data['NavBar/Media']).map((key, index)=>{
                        return <ColorizedImage 
                            key={index}
                            width='1rem' 
                            height='1rem' 
                            imageWidth='1rem' 
                            imageHeight='1rem' 
                            url={data['NavBar/Media'][key].src}
                            color='#1E0342' 
                            onClick={()=>window.open(data['NavBar/Media'][key].link, '_blank')}
                        />
                    })}
                </div>
            </div>
        </header>
    </nav>
}

function TranslateElement({scriptRef}){    
    const elementRef = useRef()
    const location = useLocation()

    //activate translate
    useEffect(()=>{
        var timeoutId
        
        if(!elementRef.current?.childNodes.length>0){
            function activateTranslate(){
                if(!scriptRef.current){
                    var addScript = document.createElement("script")
                    addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit?gt=0"
                    scriptRef.current = addScript
                    document.body.appendChild(addScript)
                }
        
                if (!(elementRef.current?.childNodes.length>0) && window.google?.translate) {
                    window.googleTranslateElementInit = new window.google.translate.TranslateElement(
                        {autoDisplay:false},"google_translate_element"
                    )
                }

                if(elementRef.current?.childNodes.length===0){
                    timeoutId = setTimeout(()=>{
                        activateTranslate()
                    }, 1000) 
                }
            }

            activateTranslate()
        }

        return ()=>clearTimeout(timeoutId)
    }, [scriptRef])

    useEffect(()=>{
        const element = document.querySelector('.goog-te-combo')

        if(element) element.value = ''
    }, [location])

    return <div ref={elementRef} id={`google_translate_element`}></div>
}
