/**
 * Renders an image with a colored background and a mask applied.
 *
 * @param {object} props - Component properties.
 *   @param {string} [props.height='2rem'] - Height of the component container (CSS unit).
 *   @param {string} [props.width='2rem'] - Width of the component container (CSS unit).
 *   @param {string} props.url - URL of the image to be masked.
 *   @param {string} [props.color='#1E0342'] - Background color for the component container (CSS color value).
 *   @param {string} [props.imageHeight='100%'] - Height of the masked image (CSS unit).
 *   @param {string} [props.imageWidth='100%'] - Width of the masked image (CSS unit).
 * @returns {JSX.Element} A JSX element representing the colorized image.
 */
export default function ColorizedImage({ id="", className="", style={}, onClick=()=>{}, height='2rem', width='2rem', url, color='#1E0342', imageHeight='100%', imageWidth='100%'}){
    const divStyles = {...{height, width}, ...style}
    return <div id={id} className={className} style={divStyles} onClick={onClick}>
        <div style={{backgroundColor:color, height:imageHeight, width:imageWidth, mask:`url("${url}")`, maskRepeat: 'no-repeat', maskSize:width, maskPosition: 'center',}}/>
    </div>
}