import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom'
import { NotifyProvider } from './Contexts/Notification/NotifyContext'
import { FirebaseAuthProvider } from './Contexts/Firebase/FirebaseAuth';
import { FirebaseProvider } from './Contexts/Firebase/FirebaseContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <NotifyProvider>
        <FirebaseAuthProvider>
          <FirebaseProvider>
            <App />
          </FirebaseProvider>
        </FirebaseAuthProvider>
      </NotifyProvider>
    </Router>
  </React.StrictMode>
);
