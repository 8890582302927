import { useState, useEffect } from 'react'
import './ReturnToTop.css'
import ColorizedImage from '../ColorizedImage'

export default function ReturnToTop(){
    const [isTop, setIsTop] = useState(window.scrollY===0)

    useEffect(()=>{
        const checkScroll = ()=>{
            setIsTop(window.scrollY===0)
        }

        window.addEventListener('scroll', checkScroll)

        return ()=>{
            window.removeEventListener('scroll', checkScroll)
        }
    }, [])

    return <>{isTop?<></>:<div className='return-to-top' onClick={()=>window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <div className='inner-return'>
            <ColorizedImage url='https://res.cloudinary.com/dywdhfbzt/image/upload/v1718790612/Icons/chevron-up-52_wgyda4.png' width='2rem' height='2rem' imageHeight='2rem' imageWidth='2rem' color='#E1F7F5' />
        </div>
    </div>}</>
}